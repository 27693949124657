/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/animate.css"
import "./src/styles/hover-min.css"
import "./src/styles/owl.carousel.css"
import "./src/styles/owl.theme.default.min.css"
import "./src/styles/custom.css"
import "./src/styles/style.css"
import "./src/styles/responsive.css"